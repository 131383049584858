import { useState, useEffect } from 'react';
import axios from 'axios';

const useGlobalLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
      setIsLoading(true);
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        setIsLoading(false);
        return response;
      },
      error => {
        setIsLoading(false);
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject interceptors
    return () => {
        axios.interceptors.request.eject(requestInterceptor);
        axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return isLoading;
};

export default useGlobalLoader;