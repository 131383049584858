import React, { useEffect, useState } from "react";
import { Row, Col, Select, Input, message, Card, Typography, Modal, Divider } from "antd";
import axios from "axios";
import { FLEXBLOK_BLOCK_EXPLORER_BASE_URL } from "../../CONSTANTS";
import { dateFormat, hexaConversion, timeFormat } from "../../utils/genericFuntions";
import expand from "../../assets/img/expand.svg";
import './Explorer.scss'
import flexblok from '../../assets/img/flexblok.png'
const { Option } = Select;
const { Group: InputGroup } = Input;


const { Title, Text } = Typography;
const SearchWithDropdown = () => {
    const [searchType, setSearchType] = useState("blockNumber");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [blocks, setBlocks] = useState([]);
    const [blockInfo, setBlockInfo] = useState({ show: false, data: null })
    const [showIncomingTransactions, setShowIncomingTransactions] = useState(false);
    const placeholderMapping = {
        "blockNumber": "Block Number",
        "txnHash": "Transaction Hash"
    }

    const handleSearch = () => {
        if (!searchQuery.trim()) {
            message.error("Please enter a valid search query.");
            return;
        }

        setSearchResults(null); // Clear previous results
        setIsLoading(true); // Show loading indicator

        const apiUrl = searchType === "blockNumber"
            ? `${FLEXBLOK_BLOCK_EXPLORER_BASE_URL}/blockGetByNumber`
            : `${FLEXBLOK_BLOCK_EXPLORER_BASE_URL}/txnGetByHash`;

        const payload = {
            rpcUrl: process.env.REACT_APP_RPC_URL,
            [searchType]: searchQuery,
        };

        axios
            .post(apiUrl, payload)
            .then((response) => {
                const results = response?.data;
                if (results) {
                    setSearchResults(results);
                } else {
                    message.warning("No results found for the given query.");
                }
            })
            .catch((error) => {
                message.error("Failed to fetch search results. Please try again.");
            })
            .finally(() => {
                setIsLoading(false); // Stop loading indicator
            });
    };

    const getBlocks = async (blockNumber) => {
        const payload = {
            rpcUrl: process.env.REACT_APP_RPC_URL,
            blockNumber: blockNumber,
        };
        const apiUrl = `${FLEXBLOK_BLOCK_EXPLORER_BASE_URL}/blockGetByNumber`;
        try {
            const response = await axios.post(apiUrl, payload);
            if (response?.data) {
                setBlocks((prevBlocks) => {
                    const newBlock = response.data;
                    const isNewBlock = !prevBlocks.some((block) => block.number === newBlock.number);

                    if (isNewBlock) {
                        const updatedBlocks = [newBlock, ...prevBlocks].filter((block) => block.transactions.length > 0);
                        return updatedBlocks.slice(0, 4); // Keep only the last 4 blocks
                    }
                    return prevBlocks; // No change if it's a duplicate
                });
            }
        } catch (error) {
            message.error("Failed to fetch search results. Please try again.");
        }
    };

  useEffect(() => {
    let intervalId;

    if (showIncomingTransactions) {
      const fetchBlocks = () => {
        getBlocks("latest");
      };
      intervalId = setInterval(fetchBlocks, 1000);
    }

    // Clean up the interval when the component unmounts or when toggling off
    return () => clearInterval(intervalId);
  }, [showIncomingTransactions]);

    const toggleShowTransactions = () => {
        setShowIncomingTransactions((prev) => !prev);
    };
    const showblockInfo = (block)=>{
        setBlockInfo({ show: true, data: block })
    }
    return (
        <div className="explorer-search">
            <Row gutter={[0, 16]} align="middle">
                {blocks?.map((block, index) => (
                    <Col key={block.number || index} className="d-flex align-items-center">
                        <Card>
                            <div className="d-flex justify-content-center align-items-center gap-2 mb-2">
                            <Title level={5} className="fw-bold m-0">
                                {hexaConversion(block?.number)}
                            </Title>
                                <div className="expandIcon cp p-1" onClick={()=>showblockInfo(block)}>
                            <img src={expand} alt='' width={14} height={16}/>
                            </div>
                            </div>
                            <div className="my-2">
                            <Text className="text-center">{block?.transactions.length} Transactions,{timeFormat(block?.timestamp)} seconds ago.</Text>
                            </div>
                            <p className="validator" title={block?.miner}>
                                Validator: {block?.miner}
                            </p>
                        </Card>
                        {
                            index !== blocks.length - 1 && 
                            <>
                            <hr className="blockLine"/>
                                <span className="icon">
                                  <img src={flexblok} alt='flexblok' />
                                </span>
                                <hr className="blockLine" />
                            </>
                        }
                    </Col>
                ))}
            </Row>
            <Modal title="Block Information" open={blockInfo?.show} footer={null} onCancel={() => setBlockInfo({ show: false, data: null })}>
            <div className="d-flex flex-column">
                    <p className='blockText m-0'>Block: {hexaConversion(blockInfo?.data?.number)}</p>
                    <p className='blockText m-0'>Miner: {blockInfo?.data?.miner}</p>
                    <p className='blockText m-0'>Hash: {blockInfo?.data?.hash}</p>
                    <p className='blockText m-0'>Transactions: {blockInfo?.data?.transactions?.length}</p>
                    <p className='blockText m-0'>Uncles: {blockInfo?.data?.uncles?.length}</p>
                    <p className='blockText m-0'>Size: {hexaConversion(blockInfo?.data?.size)}</p>
                    <p className='blockText m-0'>Gas Used: {hexaConversion(blockInfo?.data?.gasUsed)}</p>
                    <p className='blockText m-0'>Timestamp: {dateFormat(hexaConversion(blockInfo?.data?.timestamp))}</p>
                    <p className='blockText m-0'>State Root: {blockInfo?.data?.stateRoot}</p>
                    <p className='blockText m-0'>Receipt Root: {blockInfo?.data?.receiptsRoot}</p>
                    <p className='blockText m-0'>Tx Root: {blockInfo?.data?.transactionsRoot}</p>
                </div>
            </Modal>
            <div className="pt-3">
                <Row className="d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={toggleShowTransactions} disabled={isLoading}>
                            {showIncomingTransactions ? "Stop Incoming Transactions" : "Show Incoming Transactions"}
                        </button>
                </Row>  
                    {
                    showIncomingTransactions && blocks.length < 1 && ( <div className="loading text-center">
                                Waiting for incoming transaction <span className="dots"></span>
                        </div>
                    )
            }
            <Row gutter={16} align="middle py-3">
                <Col flex="auto">
                    <InputGroup compact>
                        <Select
                            value={searchType}
                            onChange={setSearchType}
                            style={{ width: 150 }}
                        >
                            <Option value="blockNumber">Block Number</Option>
                            <Option value="txnHash">Transaction Hash</Option>
                        </Select>
                        <Input
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder={`Search by ${placeholderMapping[searchType]}`}
                            style={{ width: "calc(100% - 150px)" }}
                        />
                    </InputGroup>
                </Col>
                <Col>
                    <button className="btn btn-primary" onClick={handleSearch} disabled={isLoading}>
                        {isLoading ? "Searching..." : "Search"}
                    </button>
                </Col>
            </Row>
            </div>   
            {searchResults &&
                <div className="mt-5" style={{ height: '400px', overflowY: "scroll" }}>
                    <table className='table results'>
                        <tbody>
                            {Object.keys(searchResults).map((el, i) => {
                                return <tr key={i + 1}>
                                    <td className='fw-bold'>{el}</td>
                                    <td style={{ wordBreak: 'break-word' }}>{JSON.stringify(searchResults[el]).replaceAll('"', "")}</td>
                                </tr>
                            })}
                        </tbody></table></div>
            }

        </div>
    );
};

export default SearchWithDropdown;
