import { Card, Col, message, Row, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { FLEXBLOK_BLOCK_EXPLORER_BASE_URL, RPC_URL } from '../../CONSTANTS'
import axios from 'axios'
import { clientData, NodeData, peerColumns } from '../../utils/explorerData'


const { Title, Text } = Typography;
const Nodes = ({ isActive }) => {
    const [nodeData, setNodeData] = useState(null);
    const [peerData, setPeerData] = useState(null);
    const getNodeDetails = async () => {
        try {
            const response = await axios.post(
                `${FLEXBLOK_BLOCK_EXPLORER_BASE_URL}/nodeGetDetails`,
                {
                    client: "besu",
                    rpcUrl: RPC_URL
                }
            );
            setNodeData(response?.data)
        } catch (error) {
            message.error(`Axios Error: ${error.message}`);
        }
    };

    const getNodePeerDetails = async () => {
        try {
            const response = await axios.post(
                `${FLEXBLOK_BLOCK_EXPLORER_BASE_URL}/nodeGetPeers`,
                {
                    rpcUrl: RPC_URL
                }
            );
            setPeerData(response?.data)
        } catch (error) {
            message.error(`Error: ${error.response.data.message || error.message}`)
        }
    };

    useEffect(() => {
        if (isActive) {
        // Define the function to call periodically
        const fetchDetails = () => {
            getNodeDetails();
            getNodePeerDetails();
        };

        // Call the function initially
        fetchDetails();

        // Set up an interval to call the function every 2 seconds
        const intervalId = setInterval(fetchDetails, 2000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
        }
    }, [isActive]);


    const nodeMatchingData = (label) => {
        switch (label) {
            case "Status":
                return "Running";
            case "Blocks":
                return nodeData?.blocks ? nodeData?.blocks:'--'
            case "Peers":
                return nodeData?.peers ? nodeData?.peers :'--'
            case "Queued":
                return (nodeData?.queuedTxns || nodeData?.queuedTxns===0) ? nodeData?.queuedTxns:'--'
            default:
                return '--';
        }
    }

    const clientMatchingData = (label) => {
        switch (label) {
            case "Client":
                return "besu";
            case "Node ID":
                return nodeData?.nodeId ? nodeData?.nodeId:"--"
            case "Node Name":
                return nodeData?.nodeName ? nodeData?.nodeName:'--'
            case "Enode":
                return nodeData?.enode ? nodeData?.enode:"--"
            case "RPC Url":
                return `${RPC_URL}`
            case "IP Address":
                return nodeData?.ip ? nodeData?.ip:"--"
            default:
                return '--';
        }
    }

    return (
        <div className='d-flex justify-content-center nodes'>
            <div className='nodeContainer'>
                <Row gutter={[32,16]} className='d-flex'>
                    {
                        NodeData.map((node, index) => {
                            return (
                                <Col key={index}>
                                    <Card className='p-4'>
                                        <div className='d-flex align-items-center gap-4'>
                                            <div>
                                                <img src={node.icon} alt='' style={{ width: "40px", height: "40px" }} />
                                            </div>
                                            <div>
                                                <Title level={3} className='fw-bold'>{node.label}</Title>
                                                <Title level={4} className='fw-bold text-center m-0'>{nodeMatchingData(node.label)}</Title>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Card className='my-4'>
                    {
                        clientData.map((node) => {
                            return (
                                <div className='d-flex align-items-center p-4 border-bottom'>
                                    <Text className='lh-1 fw-bold title'>{node.label}:</Text>
                                    <Text className='lh-1 value'>{clientMatchingData(node.label)}</Text>
                                </div>
                            )
                        })
                    }
                </Card>
                <Card>
                    <div className='d-flex justify-content-center mt-3 mb-2'>
                        <Text className='text-center fw-bold' style={{ color: "#4a5568" }}>Peer Information</Text>
                    </div>
                    <Table columns={peerColumns} dataSource={peerData} />
                </Card>
            </div>

        </div>
    )
}

export default Nodes