import { Breadcrumb, Dropdown, Input, Menu, Modal, Select, Table, message,DatePicker } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {  BASE_URL, DEPLOYED_URL, LOGGEDIN_USER } from '../../CONSTANTS'
import { Auth } from 'aws-amplify'
import { MoreOutlined } from '@ant-design/icons';
import { AiOutlineSearch } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'
import { dateFormat } from '../../utils/genericFuntions'


const Users = () => {

    const {RangePicker} = DatePicker;
    const [UserData, setUserData] = useState([]);
    const [currentUserData, setCurrentUserData] = useState();
    const [loading, setLoading] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [updateUserRoleModal, setUpdateUserRoleModal] = useState({
        show: false,
        userId: '',
        adminUserId: '',
        roles: []
    })
    
    const [filter, setFilter] = useState({
        search: "",
        startDate: {
            dateObj: "",
            date: ""
        },
        endDate: {
            dateObj: "",
            date: ""
        }
    })

    const updateRole = async () => {
        setUpdateUserRoleModal((prev => ({
            ...prev,
            show: false
        })))
        setLoading(true);
        const { show, email, role, ...other } = updateUserRoleModal;
        const userRoleUpdateModalData = {
            ...other,
            roles: [updateUserRoleModal.role]
        }
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
        axios.put(`${BASE_URL}/api/user/role/update`, {
            ...userRoleUpdateModalData
        }, {
            headers: {
                cognitotoken: jwtToken
            }
        }).then(res => {
            message.success("User Role Updated successfully")
            getAllUsers();
            setLoading(false);
        })
            .catch(err => {
                message.error("Error on  update user role");
                loading(false);
            })
    }
    const menu = (userId, cognitoId, data) => {
        return (
            <Menu>
                <Menu.Item onClick={() => {
                    setUpdateUserRoleModal(prev => ({
                        ...prev,
                        show: true,
                        userId: userId,
                        adminUserId: currentUserData.userInfo._id,
                        email: data.email
                    }))
                }}>Change Role</Menu.Item>
                <Menu.Item onClick={() => {
                    setShowDeleteUserModal({
                        show: true,
                        userId: userId,
                        cognitoId: cognitoId,
                    })
                }}>Delete</Menu.Item>
            </Menu>
        )
    }
    const bredCrumbItems = [
        {
            title: "USER MANAGEMENT"
          },
          {
            title: "USERS",
            href: `${DEPLOYED_URL}/users`
          }
    ]

    const userColumns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Role",
            dataIndex: 'roles',
            render: (text) => {
                const role = text.map(el=>roleData.find(ele => ele._id === el)).map(el=>el.role).join(',') ;
                return role
            }
        },
        {
            title: "Created",
            dataIndex: "createdOn",
            render: (text) => {
                return dateFormat(text);
            }
        },
        {
            title: "Action",
            dataIndex: '_id',
            render: (text, data) => {
                return (<div className='cp'>
                    <Dropdown overlay={menu(text, data.cognitoId, data)}>
                        <MoreOutlined />
                    </Dropdown>
                </div>)

            }
        }
    ]
    const [userCreationModal, setUserCreationModalData] = useState({
        show: false,
        name: null,
        email: null
    })

    const getAllUsers = async () => {
        setLoading(true)
        const currentUser = await Auth.currentAuthenticatedUser();
        const email = currentUser.attributes.email;
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
        await axios.get(`${BASE_URL}/api/user/email/${email}`, {
            headers: {
                cognitotoken: jwtToken
            }
        })
            .then(res => {
                setCurrentUserData(res.data.data)
                axios.post(`${BASE_URL}/api/user/admin/created`, {
                    adminUserId: res.data.data.userInfo._id,
                    startDate: filter.startDate.date,
                    endDate: filter.endDate.date,
                    search: filter.search
                }, {
                    headers: {
                        cognitotoken: jwtToken
                    }
                }).then(res => {
                    setLoading(false)
                    const temp = [];
                    temp.push(...res.data.data)
                    setUserData(temp)
                }).catch(err => {
                    setLoading(false)
                    setUserData([]);
                    message.error(err.response.data.message)
                })
            })

    }


    const createUserWithRole = async () => {
        setLoading(true)
        setUserCreationModalData(prev => ({
            ...prev,
            show: false
        }))
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
            const userCreationPayload = {
                email: userCreationModal.email,
                createdBy: LOGGEDIN_USER.userId,
                roles: userCreationModal.role,
                name : userCreationModal.name
            }
            axios.post(`${BASE_URL}/api/user/create`, {
                ...userCreationPayload
            }, {
                headers: {
                    cognitotoken: jwtToken
                }
            }).then(res => {
                message.success("User created successfully");
                getAllUsers();
            })
                .catch(err => {
                    message.error("Error on creating a user")
                })

    }
    useEffect(() => {
        getAllUsers();
        getAllRoles();
    }, [filter])
    const APIKeyModalFooter = () => {
        return (
            <div className='d-flex'>
                <button className='btn btn-primary me-3' onClick={createUserWithRole}>Create</button>
                <button className='btn btn-secondary' onClick={() => setUserCreationModalData(prev => ({ ...prev, show: false }))}>Cancel</button>
            </div>
        )
    }
    const [showDeleteUserModal, setShowDeleteUserModal] = useState({
        show: false,
        userId: '',
        cognitoId: ""
    });
    const deleteUser = async (user) => {
        setShowDeleteUserModal(prev => ({
            ...prev,
            show: false,
        }))
        const currentUserToken = await Auth.currentSession();
        axios.delete(`${BASE_URL}/api/user/delete`, {
            data: {
                adminUserId: currentUserData.userInfo._id,
                userId: showDeleteUserModal.userId,
            },
            headers: {
                cognitotoken: currentUserToken.accessToken.jwtToken
            }
        })
            .then(async (res) => {
                setShowDeleteUserModal({
                    show: false,
                    userId: ''
                })
                message.success('User deleted successfully');
                getAllUsers();
            })
            .catch(err => {
                message.error(err.response.data.message)
            })
    }

    const DeleteUserModalFooter = () => {
        return (
            <div className='d-flex'>
                <button className='btn btn-primary me-3' onClick={deleteUser}>Delete</button>
                <button className='btn btn-secondary' onClick={() => setUserCreationModalData(prev => ({ ...prev, show: false }))}>Cancel</button>
            </div>
        )
    }
    const getAllRoles = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
        await axios.get(`${BASE_URL}/api/role`, {
            headers: {
                cognitotoken: jwtToken
            }
        })
            .then(res => {
                const temp = [];
                temp.push(...res.data.data)
                temp.shift()
                setRoleData(temp)
            })
            .catch(err => {
                message.error(err.response.data.message)
            })
    }
    return (
        <div className='p-3 pt-1'>
            <div className='py-2 bg-white px-2 text-dark'>
                <Breadcrumb separator=">" items={bredCrumbItems} />
                <div className='mt-2'>
                    <p className='h5 p-0 m-0 text-dark mb-2 text-uppercase'>Users</p>
                    <p className='text-secondary'>You can manage FLEXBLOK users here.</p>
                </div>
            </div>
            <div className='mt-2'>
            <div className='bg-white d-flex justify-content-between w-100 mb-2 pt-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <div className='d-flex w-100 ms-2'>
                            <Input value={filter.search} className='w-50' onChange={e => setFilter(prev => ({ ...prev, search: e.target.value }))} prefix={<AiOutlineSearch />} placeholder='Search' />
                            <RangePicker allowClear={false} value={[filter.startDate.dateObj, filter.endDate.dateObj]} className='date-picker ms-2' onChange={(e, m) => { setFilter(prev => ({ ...prev, startDate: { date: m[0], dateObj: e[0] }, endDate: { date: m[1], dateObj: e[1] } })) }} />
                            <BiRefresh style={{ fontSize: '35px' }} onClick={e => setFilter(prev => ({ search: "", startDate: { dateObj: "", date: "" }, endDate: { dateObj: "", date: "" } }))} className='ms-3 cp' />
                        </div>
                    </div> 
                    <button className='btn btn-primary mb-3' onClick={() => setUserCreationModalData(prev => ({ ...prev, show: true }))}>+ Create User</button>
                </div>
                <Table showHeader={UserData.length ? true : false} columns={userColumns} dataSource={UserData} />
            </div>
            <Modal footer={<APIKeyModalFooter />} title="User Creation" open={userCreationModal.show} onCancel={() => setUserCreationModalData(prev => ({ ...prev, show: false }))}>
                <Input placeholder='Enter Name' className='my-2' value={userCreationModal.name} onChange={e => setUserCreationModalData(prev => ({ ...prev, name: e.target.value }))} />
                <Input placeholder="Enter Email Id" className='my-2' value={userCreationModal.email} onChange={e => setUserCreationModalData(prev => ({ ...prev, email: e.target.value }))} />
                <Select mode='tags' placeholder='Select Role' value={userCreationModal.role} options={roleData.map(res => ({
                    label: res.role,
                    value: res._id
                }))} className='my-2 w-100' onChange={e => setUserCreationModalData(prev => ({ ...prev, role: e }))} />
            </Modal>
            <Modal footer={<DeleteUserModalFooter />} title="Delete User" open={showDeleteUserModal.show}>
                <div className='py-2'>Are you sure want to delete the selected User?</div>
            </Modal>

            <Modal onOk={updateRole} onCancel={() => setUpdateUserRoleModal(prev => ({ ...prev, show: false }))} title="Update User" open={updateUserRoleModal.show}>
                <div>{updateUserRoleModal.email}</div>
                <Select placeholder='Select Role' value={updateUserRoleModal.role} options={roleData.map(res => ({
                    label: res.role,
                    value: res._id
                }))} className='my-2 w-100' onChange={e => setUpdateUserRoleModal(prev => ({ ...prev, role: e }))} />
            </Modal>
        </div>

    )
}

export default Users;