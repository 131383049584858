import React, { useState } from 'react';
import './Login.scss';
import { Auth } from 'aws-amplify';
import LoginBackground from '../../assets/img/login-background.jpg';
import Logo from '../../assets/img/logo_white.png';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from '../../CONSTANTS';
import { CloudWatchLogs } from 'aws-sdk';
const Login = () => {

  const [signInData, setSignInData] = useState({
    email: "",
    password: ""
  })



  const navigate = useNavigate();
  const [createAccount, setCreateAccount] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({
    newPassword: '',
    username: ''
  })
  const [signupData, setSignupData] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    cpassword: "",
    username: "",
    name: ""
  })

  const [verification, setVerification] = useState({
    show: false,
    user: ""
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showForgotPasswordVerfication, setShowForgotPasswordVerification] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
    vcode: "",
    newPassword: ""
  })

  
  const signIn = async () => {
    axios.get(`${BASE_URL}/api/public/user/exists/${signInData.email}`)
      .then(async (res) => {
        if (res.data.data) {
          await Auth.signIn(signInData.email, signInData.password)
            .then(async (res) => {
              if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setChangePassword(true);
                setChangePasswordData(prev => ({
                  ...prev,
                  username: res.username,
                  tempPassword: signInData.password,
                }))

              }
              else {
                const currentUserToken = await Auth.currentSession();
                axios.get(`${BASE_URL}/api/user/email/${signInData.email}`, {
                  headers: {
                    cognitotoken: currentUserToken.accessToken.jwtToken
                  }
                })
                  .then(response => {
                    localStorage.setItem("user-console-user-id", response.data.data.userInfo._id)
                    localStorage.setItem("jwtToken", res.signInUserSession.accessToken.jwtToken);
                    localStorage.setItem("baas-console-user", JSON.stringify(res))
                    localStorage.setItem('user-console-user', JSON.stringify(response.data.data));
                    navigate("/deployedContracts")
                  })
              }
            })
            .catch(err => {
              message.error(err.message)
            })
        }
        else {
          message.error("User does not exist")
        }

      })

  }

  const signUp = async () => {
    const signupPayload = {
      username: signupData.username,
      password: signupData.password,
      attributes: {
        name: signupData.name,
        email: signupData.email,
        phone_number: signupData.phoneNumber,
      }
    }
    await Auth.signUp(signupPayload).then(response => {
      const payload = {
        ...signupPayload,
        ...signupPayload.attributes,
        phoneNumber: signupPayload.attributes.phone_number,
        "companyName": "INF",
        "admin": true,
        "permission": "ALL",
        "cognitoId": response.userSub
      }
      setVerification({
        show: true,
        user: response,
        payload: payload
      });
      setCreateAccount(false);
    })
      .catch(err => {
        message.error(err.message)
      })
  }

  const confirmSignUp = async () => {
    await Auth.confirmSignUp(verification.user.user.username, signupData.vcode + "")
      .then(res => {
        axios.post(`${BASE_URL}/api/user/create`, verification.payload)
          .then(res => {
            message.success("Account Created successfully")
            setCreateAccount(false);
            setVerification({ show: false, user: "" })
          })
          .catch(err => {
            console.log(err.message)
          })
      })
      .catch(err => {
        message.error(err.message)
      })
  }


  const sendVerficationCode = async () => {
    await Auth.forgotPassword(forgotPasswordData.email)
      .then(res => {

        setShowForgotPasswordVerification(true);
        setShowForgotPassword(false)
        setShowForgotPasswordVerification(true)
      }).catch(err => { message.error(err.message) })
  }
  const resetPassword = () => {
    Auth.forgotPasswordSubmit(forgotPasswordData.email, forgotPasswordData.vcode, forgotPasswordData.newPassword)
      .then(res => {
        alert("Password updated successfully");
        setShowForgotPasswordVerification(false);
        setShowForgotPassword(false)
      })
      .catch(err => {
        message.error(err.message)
      })
  }

  const updatePassword = async () => {

    try {
      const user = await Auth.signIn(changePasswordData.username, changePasswordData.tempPassword);
      await Auth.completeNewPassword(user, changePasswordData.newPassword);
      message.success('Password reset successfully!');
      setChangePassword(false)
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <div className="half">
      <div className="bg order-1 order-md-2" style={{ backgroundImage: 'url(' + LoginBackground + ')' }}></div>
      <img src={Logo} alt="" className='logo logo-login' />
      <div className="contents order-2 order-md-1">

        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6">
              <div className="form-block">
                <div className="text-center mb-5">
                  {!createAccount && !verification.show && <h3>Login to <strong>FLEXBLOK</strong></h3>}
                  {createAccount && <h3><strong>Create Account</strong></h3>}
                  {verification.show && <h3><strong>Verification</strong></h3>}

                  {/* <!-- <p className="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p> --> */}
                </div>
                {!createAccount && !verification.show && !showForgotPassword && !showForgotPasswordVerfication && !changePassword && <div>
                  <div className="form-group first mb-4">
                    <label for="username" className='my-2'>Email</label>
                    <input value={signInData.email} onChange={e => setSignInData(prev => ({ ...prev, email: e.target.value }))} type="text" className="form-control" placeholder="Your Email" id="username" autoComplete='off' />
                  </div>
                  <div className="form-group last">
                    <label for="password" className='my-2'>Password</label>
                    <input value={signInData.password} onChange={e => setSignInData(prev => ({ ...prev, password: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Your Password" id="password" />
                  </div>

                  <div className="text-secondary my-3 float-end">
                    <span className="forgot-pass" onClick={() => {
                      setShowForgotPassword(true)
                    }}>Forgot Password</span>
                  </div>

                  <button onClick={signIn} className=' btn btn-block btn-primary w-100'>Log In </button>

                  <div className='text-center mt-3'>Don't have account ? <span onClick={() => setCreateAccount(true)} className='text-primary'>Create Account</span> </div>

                </div>}
                {createAccount && <div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>Name</label>
                    <input value={signupData.name} onChange={e => setSignupData(prev => ({ ...prev, name: e.target.value }))} autocomplete='off' className="form-control" placeholder="Your Name" id="password" />
                  </div>
                  <div className="form-group first mb-4">
                    <label for="username" className='my-2'>Username</label>
                    <input value={signupData.username} onChange={e => setSignupData(prev => ({ ...prev, username: e.target.value }))} type="text" className="form-control" placeholder="Your Username" id="username" autoComplete='off' />
                  </div>
                  <div className="form-group first mb-4">
                    <label for="username" className='my-2'>Phone Number</label>
                    <input value={signupData.phoneNumber} onChange={e => setSignupData(prev => ({ ...prev, phoneNumber: e.target.value }))} type="text" className="form-control" placeholder="Your Phonenumber" id="username" autoComplete='off' />
                  </div>
                  <div className="form-group first mb-4">
                    <label for="username" className='my-2'>Email</label>
                    <input value={signupData.email} onChange={e => setSignupData(prev => ({ ...prev, email: e.target.value }))} type="text" className="form-control" placeholder="Your Email" id="username" autoComplete='off' />
                  </div>
                  <div className="form-group last mb-4">
                    <label for="password" className='my-2'>Password</label>
                    <input value={signupData.password} onChange={e => setSignupData(prev => ({ ...prev, password: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Your Password" id="password" />
                  </div>
                  <div className="form-group last mb-4">
                    <label for="cpassword" className='my-2'>Confirm Password</label>
                    <input value={signupData.cpassword} onChange={e => setSignupData(prev => ({ ...prev, cpassword: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Confirm Password" id="password" />
                  </div>
                  <input onClick={signUp} className=' btn btn-block btn-primary w-100' value="Sign Up" />
                  <div className='text-center mt-3'>Already have an account? <span onClick={() => setCreateAccount(false)} className='text-primary'>Sign In</span> </div>
                </div>}
                {verification.show && <div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>Verfication Code</label>
                    <input value={signupData.vcode} onChange={e => setSignupData(prev => ({ ...prev, vcode: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Enter verification code sent to maill" id="password" />
                  </div>
                  <input onClick={confirmSignUp} className=' btn btn-block btn-primary w-100' value="Submit" />
                </div>}
                {showForgotPassword && <div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>Email</label>
                    <input value={forgotPasswordData.email} onChange={e => setForgotPasswordData(prev => ({ ...prev, email: e.target.value }))} autocomplete='off' type="email" className="form-control" placeholder="Enter mail id to send verification code" id="password" />
                  </div>
                  <input onClick={sendVerficationCode} className=' btn btn-block btn-primary w-100' value="Submit" />
                </div>}
                {showForgotPasswordVerfication && <div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>Verification Code</label>
                    <input value={forgotPasswordData.vcode} onChange={e => setForgotPasswordData(prev => ({ ...prev, vcode: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Enter verification code sent to maill" id="password" />
                  </div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>New Password</label>
                    <input value={forgotPasswordData.newPassword} onChange={e => setForgotPasswordData(prev => ({ ...prev, newPassword: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Enter verification code sent to maill" id="password" />
                  </div>
                  <input onClick={resetPassword} className=' btn btn-block btn-primary w-100' value="Submit" />
                </div>}
                {changePassword && <div>
                  <div className="form-group last mb-4">
                    <label for="name" className='my-2'>New Password</label>
                    <input value={changePasswordData.newPassword} onChange={e => setChangePasswordData(prev => ({ ...prev, newPassword: e.target.value }))} autocomplete='off' type="password" className="form-control" placeholder="Enter new password" id="password" />
                  </div>
                  <input onClick={updatePassword} className=' btn btn-block btn-primary w-100' value="Submit" />
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;