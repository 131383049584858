import React, { useEffect, useState } from 'react';
import { ApiOutlined, GroupOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import './Sider.scss';
import Logo from '../../assets/img/logo.png'
import { AppstoreOutlined } from '@ant-design/icons';
import graphana from '../../assets/img/grphana.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { DEPLOYED_URL, USER } from '../../CONSTANTS';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import { BiWorld } from 'react-icons/bi'
const { Sider } = Layout;

const App = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const location = useLocation();
  const [siderSelected, setSiderSelected] = useState({
    key: ['7'],
    sub: ['sub']
  })

  const mapLocations = {
    "/deployedContracts": {
      key: ["7"], sub: [`sub-1`, 'sub']
    },
    "/allTransactions": {
      key: ["8"], sub: [`sub-2`, 'sub']
    },
    "/allEvents": {
      key: ["9",], sub: [`sub-3`, 'sub']
    },
    "/authentication": {
      key: ["16"], sub: [`sub-7`]
    },
    "/grafana": {
      key: ["12"], sub: ['sub-6']
    },
    "/swagger": {
      key: ["14"], sub: ''
    },
    "/users": {
      key: ["15"], sub: ['sub-5']
    },
    "/accounts": {
      key: ["17"], sub: [`sub-0`, 'sub']
    },
    "/block-explorer": {
      key: ["13"]
    },
  }

  useEffect(() => {
    if (location.pathname) {
      const temp = mapLocations[location.pathname];
      if (temp) {
        setSiderSelected((prev) => ({
          ...prev,
          key: temp.key,
          sub: Array.from(new Set([...prev.sub, ...(temp.sub || [])])),
        }));
      }
    }
  }, [location.pathname]);
  

  const items = [
    getItem('API & Services', 'sub', <AppstoreOutlined />, [
      getItem('ACCOUNT API', 'sub-0', null, [getItem(<div onClick={() => navigate('/accounts')}>Accounts</div>, '17')]),
      getItem('CONTRACT API', 'sub-1', null, [getItem(<div onClick={() => navigate('/deployedContracts')}>Deployed Contracts</div>, '7')]),
      getItem('TRANSACTION API', 'sub-2', null, [getItem(<div onClick={() => navigate('/allTransactions')}>Contract Transactions</div>, '8')]),
      getItem('EVENT API', 'sub-3', null, [getItem(<div onClick={() => navigate('/allEvents')}>Contract Events</div>, '9')]),
      // getItem('SECURITY', 'sub-4', null, [getItem(<span onClick={() => navigate('/authentication')}>Authentication</span>, '10')]),
      USER?.clusterInfo?.name !== 'avs' ? getItem(<div className='d-flex align-items-center justify-content-center' onClick={() => navigate("/swagger")}><ApiOutlined />API Explorer</div>, '14') : '',
    ]),
    getItem('Security', 'sub-7', <AiOutlineLock />, [
      getItem(<div onClick={() => navigate('/authentication')}>Authentication</div>, '16'),
    ]),
    USER?.userInfo?.isAdmin ? getItem('User Management', 'sub-5', <AiOutlineUser />, [
      getItem(<div onClick={() => navigate("/users")}>Users</div>, '15'),
    ]) : '',
    getItem('Network Monitoring', 'sub-6', <BiWorld />, [
      //getItem(<a rel='noreferrer' className='text-decoration-none' href={`http://${USER?.clusterInfo?.enodeIp}:9090/graph`} target='_blank'>Prometheus</a>, '11', <BlockOutlined />),
      // getItem(<div onClick={() => navigate("/grafana")}>Metrics Tool</div>, '12', <img className='sider-icon' src={graphana} alt='graphana' />),
      getItem(<div className='d-flex align-items-center justify-content-center' onClick={() => navigate("/block-explorer")}> <GroupOutlined />Flexblok Explorer</div>, '13',),
    ])
  ];


  const onOpen = (e) => {
    setSiderSelected(prev => ({
      ...prev,
      sub: e
    }))
  };
  const onClick = (e) => {
    setSiderSelected(prev => ({
      ...prev,
      key: e.key
    }))
  };

  return (
    <>
      <Sider className='sider' theme='light' trigger={null} collapsible collapsed={collapsed}>
        <div>
          <img src={Logo} alt="Logo" className="logo mb-2" />
          <div className="badge text-dark">
            Customer Portal
          </div>
        </div>
        <Menu
          selectedKeys={siderSelected.key}
          openKeys={siderSelected.sub}
          onClick={onClick}
          mode="inline"
          items={items}
          className='mt-3 siderMenu'
          onOpenChange={onOpen}
        />
      </Sider>
    </>

  );
};
export default App;